import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import TimerModal from '../../Components/Modals/TimerModal/TimerModal';
import { logoutAction } from '../../api/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const TimerWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { isLogged } = useSelector(state => state.auth);

  const handleOnIdle = () => {
    isLogged && setOpen(true);
  };

  // to handle if user is active, after inactivity will be logout
  useIdleTimer({
    timeout: 1000 * 60 * 30, //30 minutes
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true
    }
  });

  return (
    <>
      {children}
      {open && (
        <TimerModal
          open={open}
          handleClose={() => setOpen(false)}
          handleClick={() => {
            queryClient.removeQueries();
            dispatch(logoutAction(() => navigate('/login')));
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

TimerWrapper.propTypes = {
  children: PropTypes.node
};

export default TimerWrapper;

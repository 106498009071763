import axios from 'axios';
import { setLoading, setIsLogged, setUsername } from '../redux/slices/authSlice';
import { apiData } from '../../Global/apiConstants';
import { api } from '../api';
import { setCookie, deleteCookie, getCookieValue, getBasename } from '../../Global/globalFunctions';
import { addNotification } from '../redux/slices/notificationsSlice';
import { resetState } from '../redux/slices/dataTableSlice';
import { resetState as resetTreeState } from '../redux/slices/treeSlice';

export const authUserAction = (authData, handleSuccess, handleCodeRequired) => async dispatch => {
  // to make sure that cookies are deleted
  logout();
  dispatch(setLoading(true));

  const config = {
    method: 'POST',
    url: `${apiData()}/auth/login`,
    responseType: 'json',

    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': window.localStorage.getItem('i18nextLng')
    },
    data: {
      username: authData.username,
      password: authData.password
    }
  };

  await axios
    .request(config)
    .then(response => {
      if (response.data.message?.includes('Two Factor Authentication Required')) {
        // Requires 2FA authentication
        setCookie('temporaryToken', response.data.temporary_token);
        handleCodeRequired();
      } else {
        // Successful login
        isUserAdminAction(response.data.access_token, isAdmin => {
          setCookie('accessToken', response.data.access_token);
          setCookie('refreshToken', response.data.refresh_token);
          setCookie('username', authData.username);
          setCookie('authority', isAdmin);

          dispatch(setUsername(authData.username));
          dispatch(setIsLogged(true));
          handleSuccess();
        });
      }
    })
    .catch(err => {
      if (err.response.status === 401) {
        const fullMessage = err.response.data ? err.response.data.message : err.response.message;
        dispatch(
          addNotification({
            type: 'error',
            message:
              err.response.data.message === 'Invalid username or password'
                ? 'badCredentials'
                : 'userDisabled',
            fullMessage
          })
        );
      } else {
        dispatch(addNotification({ type: 'error', message: 'somethingWentWrong' }));
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const authUserWithCode = (authData, success) => async dispatch => {
  // to make sure that cookies are deleted
  logout();
  dispatch(setLoading(true));

  const config = {
    method: 'POST',
    url: `${apiData()}/auth/two-factor-auth`,
    responseType: 'json',

    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      temporary_token: getCookieValue('temporaryToken'),
      code: authData.code
    }
  };

  await axios
    .request(config)
    .then(response => {
      isUserAdminAction(response.data.access_token, isAdmin => {
        setCookie('accessToken', response.data.access_token);
        setCookie('refreshToken', response.data.refresh_token);
        setCookie('username', authData.username);
        setCookie('authority', isAdmin);

        dispatch(setUsername(authData.username));
        dispatch(setIsLogged(true));
        success();
      });
    })
    .catch(err => {
      if (
        err.response.status === 401 &&
        err.response.data.includes('Invalid two factor authentication code')
      ) {
        dispatch(addNotification({ type: 'error', message: 'invalidCode' }));
      } else if (err.response.status === 400) {
        dispatch(
          addNotification({
            type: 'error',
            message:
              err.response.data.error_description === 'User is disabled'
                ? 'userDisabled'
                : 'badCredentials'
          })
        );
      } else {
        dispatch(addNotification({ type: 'error', message: 'userDoesntExist' }));
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const authUserWithRefreshTokenAction = onSuccess => dispatch => {
  const config = {
    method: 'POST',
    url: `${apiData()}/auth/token`,
    responseType: 'json',

    headers: {
      Authorization: `Bearer ${getCookieValue('refreshToken')}`
    }
  };

  axios
    .request(config)
    .then(response => {
      setCookie('accessToken', response.data.access_token);
      setCookie('refreshToken', response.data.refresh_token);
      onSuccess();
    })
    .catch(() => {
      logout();
      dispatch(setIsLogged(false));
      dispatch(resetState());
      dispatch(resetTreeState());
    });
};

const isUserAdminAction = (accessToken, onSuccess) => {
  const config = {
    method: 'GET',
    url: `${apiData()}/api/users/isAdmin`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json'
    }
  };

  axios
    .request(config)
    .then(response => {
      onSuccess(response.data);
    })
    .catch(err => {
      console.error(err);
    });
};

export const logoutAction = navigateAfter => dispatch => {
  dispatch(setIsLogged(false));
  dispatch(resetState());
  dispatch(resetTreeState());

  api.delete('/auth/logout').finally(() => {
    logout();
    navigateAfter?.();
  });
};

export const logout = () => {
  deleteCookie('accessToken');
  deleteCookie('refreshToken');
  deleteCookie('username');
  deleteCookie('authority');
};

export const resetPasswordAction = async (user, email) => {
  const lang = window.localStorage.getItem('i18nextLng');
  const config = {
    method: 'POST',
    url: `${apiData()}/public/passwordRecovery`,
    responseType: 'json',
    data: { username: user, email: email },
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': lang
    }
  };

  const { data } = await axios.request(config);
  return data;
};

export const recoverPassword = async (token, requestData) => {
  const { data } = await axios.request({
    method: 'POST',
    url: `${apiData()}/public/passwordRecovery/${token}`,
    responseType: 'json',
    data: requestData,

    headers: {
      'Content-Type': 'application/json'
    }
  });

  return data;
};

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getTheme, useDarkMode } from '../../Theme';
import { useThemeColors } from '../../Components/Home/Config/TabPanelWrapper/TabPanels/ThemePanel/ThemePanelHelpers';
import { ThemeProvider } from '@mui/material';

const ThemeWrapper = ({ children }) => {
  const [themeMode, themeToggler] = useDarkMode();
  const { themeColors, logo } = useThemeColors();
  const theme = useMemo(
    () => getTheme(themeMode, themeToggler, themeColors, logo),
    [themeMode, themeToggler, themeColors, logo]
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

ThemeWrapper.propTypes = {
  children: PropTypes.node
};

export default ThemeWrapper;

import {
  Home as HomeIcon,
  DisplaySettings,
  Group,
  Engineering,
  AccountTree,
  AddTask
} from '@mui/icons-material';

import { lazy } from 'react';

const HomePage = lazy(() => import('../../Components/Home/Dashboard/Dashboard'));
const TasksPage = lazy(() => import('../../Components/Home/Tasks/Tasks'));
const ConfigPage = lazy(() => import('../../Components/Home/Config/Config'));
const UsersPage = lazy(() => import('../../Components/Home/Users/Users'));
const AuthoritiesPage = lazy(() => import('../../Components/Home/Authorities/Authorities'));
const WorkflowsPage = lazy(() => import('../../Components/Home/Workflows/Workflows'));
const LoginPage = lazy(() => import('../../Components/PublicPages/Login'));
const RecoverPage = lazy(() => import('../../Components/PublicPages/Recover'));
const NotFoundPage = lazy(() => import('../../Components/PublicPages/NotFound'));
const ErrorPage = lazy(() => import('../../Components/PublicPages/ErrorPage'));
const ContactPage = lazy(() => import('../../Components/PublicPages/Contact'));
const NotAuthorizedPage = lazy(() => import('../../Components/PublicPages/NotAuthorized'));
const PasswordRecoverPage = lazy(() => import('../../Components/PublicPages/PasswordRecoverPage'));

export const RouteNames = {
  home: `/home`,
  tasks: `/tasks`,
  config: `/config`,
  users: `/users`,
  authorities: `/authorities`,
  workflows: `/workflows`,
  login: `/login`,
  recover: `/recover`,
  notfound: `/notfound`,
  error: `/error`,
  contact: `/contact`,
  notAuthorized: `/notAuthorized`,
  passwordRecover: `/passwordRecovery`
};

// path related to router location in project
export const publicPages = [
  { path: RouteNames.login, element: <LoginPage /> },
  { path: RouteNames.recover, element: <RecoverPage /> },
  { path: RouteNames.notfound, element: <NotFoundPage /> },
  { path: RouteNames.error, element: <ErrorPage /> },
  { path: RouteNames.contact, element: <ContactPage /> },
  { path: RouteNames.passwordRecover, element: <PasswordRecoverPage /> },
  {
    path: RouteNames.notAuthorized,
    element: <NotAuthorizedPage />
  },
  {
    path: RouteNames.notfound,
    element: <NotFoundPage />
  }
];

export const privatePages = [
  {
    path: RouteNames.home,
    element: <HomePage />,
    menuLabel: `home`,
    menuIcon: HomeIcon,
    forAdminOnly: false,
    webOnly: false
  },
  {
    path: RouteNames.tasks,
    element: <TasksPage />,
    menuLabel: `tasks`,
    menuIcon: AddTask,
    forAdminOnly: false,
    webOnly: false
  },
  {
    path: RouteNames.config,
    element: <ConfigPage />,
    menuLabel: `config`,
    menuIcon: DisplaySettings,
    forAdminOnly: true,
    webOnly: true
  },
  {
    path: RouteNames.users,
    element: <UsersPage />,
    menuLabel: `users`,
    menuIcon: Group,
    forAdminOnly: true,
    webOnly: true
  },
  {
    path: RouteNames.authorities,
    element: <AuthoritiesPage />,
    menuLabel: `authorities`,
    menuIcon: Engineering,
    forAdminOnly: true,
    webOnly: true
  },
  {
    path: RouteNames.workflows,
    element: <WorkflowsPage />,
    menuLabel: `workflows`,
    menuIcon: AccountTree,
    forAdminOnly: true,
    webOnly: true
  }
];

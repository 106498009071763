import React, { Suspense, useEffect } from 'react';
import Router from './AppWrappers/Router/Router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLanguage } from './Global/LanguageToggler/languageTogglerHelpers';
import Loader from './Global/HelperComponents/Loader';
import QueryClientWrapper from './AppWrappers/QueryClientWrapper/QueryClientWrapper';
import NotificationsContainer from './AppWrappers/NotificationsContainer/NotificationsContainer';
import ThemeWrapper from './AppWrappers/ThemeWrapper/ThemeWrapper';
import { useAppMetadata } from './Global/globalHooks';

function App() {
  const { toggleLanguage } = useLanguage();

  useEffect(() => {
    if (sessionStorage.getItem('language')) toggleLanguage(sessionStorage.getItem('language'));
  }, []);

  useAppMetadata();
  //prevent to take screenshot by writing empty screen to clipboard
  // TODO: replace clipboard content with empty string after screenshot on MacOS
  useEffect(() => {
    const handlePrintScreen = e => {
      if (e.key === 'PrintScreen') {
        navigator.clipboard.writeText('');
        // alert('Screenshots disabled!');
      }
    };

    const handleKeyUp = e => {
      if (e.key === 'PrintScreen') {
        navigator.clipboard.writeText('');
        alert('Screenshots disabled!');
      }
    };

    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handlePrintScreen);
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <QueryClientWrapper>
        <DndProvider backend={HTML5Backend}>
          <ThemeWrapper>
            <Router />
          </ThemeWrapper>
        </DndProvider>
        <NotificationsContainer />
      </QueryClientWrapper>
    </Suspense>
  );
}

export default App;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import { useLanguage } from './languageTogglerHelpers';
import { flags } from '../../Img/Svg/flags';
import { StyledFlagBox } from '../StyledComponents/styledComponents';

const LanguageModeToggler = ({ sx }) => {
  const { language, toggleLanguage } = useLanguage();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        boxShadow: theme.palette.shadow.primary,
        borderRadius: 1,
        p: 1 / 2,
        ...sx
      }}
    >
      <StyledFlagBox
        data-testid='sk-toggler'
        onClick={() => toggleLanguage('sk')}
        active={language === 'sk'}
      >
        <Box sx={{ height: 18 }}>{flags.sk}</Box>
      </StyledFlagBox>
      <StyledFlagBox
        data-testid='en-toggler'
        onClick={() => toggleLanguage('en')}
        active={language === 'en'}
      >
        <Box sx={{ height: 18 }}>{flags.en}</Box>
      </StyledFlagBox>
      <StyledFlagBox
        data-testid='de-toggler'
        onClick={() => toggleLanguage('de')}
        active={language === 'de'}
      >
        <Box sx={{ height: 18 }}>{flags.de}</Box>
      </StyledFlagBox>
    </Box>
  );
};

LanguageModeToggler.propTypes = {
  sx: PropTypes.object
};

export default LanguageModeToggler;

import { isEmpty } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { getThemeAction, updateThemeAction } from '../../../../../../api/actions/globalActions';
import { defaultThemeColor, defaultTitle } from '../../../../../../Global/constants';
import defaultThemeLogo from '../../../../../../Img/dms_logo.png';
import defaultFavicon from '../../../../../../Img/favicon.ico';

function lightenDarkenColor(color, percent) {
  var num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt;
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  );
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    : null;
}
export const colors = color => {
  return {
    PRIMARY: color,
    LIGHTPRIMARY: `rgba(${hexToRgb(color)}, 0.3)`,
    DARKPRIMARY: lightenDarkenColor(color, -22),
    DARKPRIMARYlight: lightenDarkenColor(color, -12),
    SECONDARY: '#d1d1d1',
    ERROR: '#f06e6e',
    BLACK: '#000000',
    DARKPAPER: '#4C4C4C',
    DARKDARKPAPER: '#333333',
    DARKLIGHTPAPER: '#666666',
    LIGHTGRAY: '#d3d3d3',
    GRAY: '#949494',
    WHITE: '#ffffff',
    YELLOW: '#bfa110',
    HOVER: 'rgb(193 193 193 / 20%)'
  };
};
export const useThemeColors = () => {
  const [themeColors, setThemeColors] = useState(
    colors(window.localStorage.getItem('themeColor') || defaultThemeColor)
  );
  const [logo, setLogo] = useState();
  const [favicon, setFavicon] = useState();
  const [title, setTitle] = useState('');

  const [color, setColor] = useState({});
  const [photoToUpload, setPhotoToUpload] = useState({});

  useEffect(() => {
    getThemeAction(({ color, logo, favicon, title }) => {
      setThemeColors(colors(color));
      if (color !== window.localStorage.getItem('themeColor')) {
        window.localStorage.setItem('themeColor', color);
      }
      setLogo(logo);
      setTitle(title);
    });
  }, []);

  const handleSetThemeColor = () => {
    updateThemeAction(
      isEmpty(color) ? themeColors.PRIMARY : color.hex,
      isEmpty(photoToUpload) ? null : photoToUpload,
      title,
      favicon,
      () => {
        if (!isEmpty(color)) {
          setThemeColors(colors(color.hex));
          window.localStorage.setItem('themeColor', color.hex);
        }
        window.location.reload();
      }
    );
  };

  const useDefaultTheme = () => {
    Promise.all([fetch(defaultThemeLogo), fetch(defaultFavicon)])
      .then(async ([logoRes, faviconRes]) => {
        const logoBlob = await logoRes.blob();
        const logoFile = new File(
          [logoBlob],
          'logo.png',
          { type: 'image/png', lastModified: new Date().getTime() },
          'utf-8'
        );

        const faviconBlob = await faviconRes.blob();
        const faviconFile = new File(
          [faviconBlob],
          'favicon.ico',
          { type: 'image/x-icon', lastModified: new Date().getTime() },
          'utf-8'
        );

        updateThemeAction(defaultThemeColor, logoFile, defaultTitle, faviconFile, () => {
          setThemeColors(colors(defaultThemeColor));
          window.localStorage.setItem('themeColor', defaultThemeColor);
          window.location.reload();
        });
      })
      .catch(error => {
        console.error('Error fetching resources:', error);
      });
  };

  return {
    themeColors,
    logo,
    color,
    photoToUpload,
    setPhotoToUpload,
    setColor,
    handleSetThemeColor,
    useDefaultTheme,
    favicon,
    setFavicon,
    title,
    setTitle
  };
};

export function useFileUpload({ onStarting } = {}) {
  const uploadFile = useCallback(
    file => {
      if (!file) {
        return;
      }
      onStarting(file);
    },
    [onStarting]
  );

  return [uploadFile];
}

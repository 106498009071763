import { Box, CircularProgress, Typography } from '@mui/material';

const Loader = () => (
  <Box width={1} height={1} sx={{ position: 'relative' }}>
    <Box width={1} height={1} sx={{ textAlign: 'center', position: 'absolute' }}>
      <Typography sx={{ fontSize: '20vh', color: 'whitesmoke' }}>DMS</Typography>
    </Box>

    <Box
      width={1}
      sx={{
        position: 'absolute',
        top: '0%',
        transform: 'translateY(50%)',
        textAlign: 'center'
      }}
    >
      <CircularProgress sx={{ width: '10% !important', height: '10% !important' }} />
    </Box>
  </Box>
);
export default Loader;

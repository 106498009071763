import { useEffect, useMemo, useState } from 'react';
import {
  getDocumentContentByVersionAction,
  getDocumentContentUrlByVersionAction
} from '../api/actions/contentsActions';
import { defaultTitle, docxMimeType, fileTypes } from './constants';
import { getHtmlContentAction } from '../api/actions/htmlsActions';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  addItemByTargetAction,
  deleteItemByTargetAndIdAction,
  getDataByTargetAction,
  getServerUrl,
  updateItemByTargetAction
} from '../api/actions/globalActions';
import { getAllUsersAction } from '../api/actions/usersActions';
import { isEmpty, sortBy } from 'lodash';

import { getBasenameFromUrl } from './globalFunctions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setObjectTypeAndId } from '../api/redux/slices/objectTypeSlice';

export const useObjectTypes = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const objectData = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (objectData.size === 0) return;
    dispatch(
      setObjectTypeAndId({
        objectId: objectData.get('id'),
        objectType: objectData.get('objectType'),
        tableRow: objectData.get('row')
      })
    );
  }, [objectData]);
};

export const useDocumentContent = (fileId, version, dataTableViewType, mimetype) => {
  // TODO: make props as object
  const [document, setContent] = useState({});

  useEffect(() => {
    if (fileId) {
      if (dataTableViewType === fileTypes.HTML) {
        getHtmlContentAction(fileId, new AbortController()).then(response =>
          setContent({ preview: response })
        );
      } else if (mimetype && mimetype === docxMimeType) {
        // dirty solution, task should not even show button to open editor
        getDocumentContentByVersionAction(fileId, version).then(response =>
          setContent({ preview: response })
        );
      } else {
        getDocumentContentUrlByVersionAction(fileId, version).then(response =>
          setContent({ preview: response })
        );
      }
    }
  }, [fileId]);

  return [document];
};

export const useListFilter = (defaultData, withSort, target) => {
  const [filtredData, setFiltredData] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    if (target !== '') {
      setFilterValue('');
      setFiltredData([]);
    }
  }, [target]);

  const deleteFromFilter = (object, accessor) => {
    if (!isEmpty(filtredData)) {
      setFiltredData(
        filtredData.filter(data => {
          if (accessor) {
            return data[accessor] !== object[accessor];
          } else {
            return data !== object;
          }
        })
      );
    }
  };

  const handleFilter = (e, accessorr) => {
    setFilterValue(e.target.value);
    if (e.target.value !== '') {
      setFiltredData(
        withSort
          ? sortBy(
              defaultData?.filter(el => {
                if (accessorr) {
                  return el[accessorr].toUpperCase().includes(e.target.value.toUpperCase());
                } else {
                  return el.toUpperCase().includes(e.target.value.toUpperCase());
                }
              }),
              [
                function (item) {
                  if (accessorr) {
                    return item[accessorr].toUpperCase();
                  } else {
                    return item.toUpperCase();
                  }
                }
              ]
            )
          : defaultData?.filter(el => {
              if (accessorr) {
                return el[accessorr].toUpperCase().includes(e.target.value.toUpperCase());
              } else {
                return el.toUpperCase().includes(e.target.value.toUpperCase());
              }
            })
      );
    } else {
      setFiltredData([]);
    }
  };

  return { filtredData, filterValue, handleFilter, deleteFromFilter };
};
export const useUsersAuthorities = target => {
  const queryClient = useQueryClient();
  const [filtredData, setFiltredData] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [deleteModal, setDeleteModal] = useState({ open: false, value: {}, top: 0, left: 0 });
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState({ open: false, value: {} });

  const { data: users, isLoading: l1 } = useQuery(['users'], getAllUsersAction);
  const { data: authorities, isLoading: l5 } = useQuery(['authorities'], () =>
    getDataByTargetAction('authorities')
  );

  const { mutate: handleAdd, isLoading: l3 } = useMutation(
    item => addItemByTargetAction(target, item),
    {
      onSuccess: async newItem => {
        queryClient.setQueryData(target, old => [
          ...old,
          { ...newItem, ...(target === 'users' && { id: newItem.username }) }
        ]);
      }
    }
  );

  const { mutate: handleUpdate, isLoading: l2 } = useMutation(
    item => updateItemByTargetAction(item, target),
    {
      onSuccess: async updatedItem => {
        queryClient.setQueryData(target, old =>
          old.map(item =>
            item.id === updatedItem[target === 'users' ? 'username' : 'id']
              ? { ...updatedItem, ...(target === 'users' && { id: updatedItem.username }) }
              : item
          )
        );
      }
    }
  );

  const { mutate: handleDelete, isLoading: l4 } = useMutation(
    id => deleteItemByTargetAndIdAction(target, id),
    {
      onSuccess: async (data, id) => {
        queryClient.setQueryData(target, old => {
          return old.filter(item => item.id !== id);
        });
      }
    }
  );

  const handleFilter = e => {
    setFilterValue(e.target.value);
    if (e.target.value !== '') {
      setFiltredData(
        (target === 'users' ? users : authorities).filter(el =>
          el[target === 'users' ? 'username' : 'name']
            .toUpperCase()
            .includes(e.target.value.toUpperCase())
        )
      );
    } else {
      setFiltredData([]);
    }
  };

  const toggleModal = (type, value, e) => {
    switch (type) {
      case 'add':
        setAddModal(prev => !prev);
        break;
      case 'edit':
        setEditModal({ open: !editModal.open, value: value });
        break;
      case 'delete':
        setDeleteModal({
          open: !deleteModal.open,
          value: value,
          top: e?.clientY,
          left: e?.clientX
        });
        break;
      default:
        break;
    }
  };
  const isLoading = l1 || l2 || l3 || l4 || l5;

  return {
    users,
    authorities,
    filtredData,
    filterValue,
    isLoading,
    addModal,
    editModal,
    deleteModal,
    toggleModal,
    handleFilter,
    handleAdd,
    handleDelete,
    handleUpdate
  };
};

export const useAppMetadata = () => {
  useEffect(() => {
    // Set favicon url
    const baseUrl = process.env.NODE_ENV === 'production' ? '.' : 'http://localhost:8080';
    document.getElementById('favicon').href = `${baseUrl}/public/theme/favicon`;

    // Set title
    fetch(`${baseUrl}/public/theme/title`)
      .then(response => response.text())
      .then(data => {
        document.title = data || defaultTitle;
      })
      .catch(error => {
        console.error('Error fetching app title:', error);
      });
  }, []);
};

export const parseUsers = rawRows => {
  rawRows = rawRows.map(rawRow => {
    return { ...rawRow, id: rawRow.username };
  });

  return rawRows;
};

export const useRouterBasename = () => {
  const { data, isError, isLoading } = useQuery(
    'server_basename',
    async () => {
      const { data } = await getServerUrl();
      return getBasenameFromUrl(data);
    },
    { staleTime: Infinity, cacheTime: Infinity }
  );

  const basename = useMemo(() => data, [data]);

  return { basename, isError, isLoading };
};

import React from 'react';
import RoutesWrapper from './RoutesWrapper';
import TimerWrapper from '../../Global/HelperComponents/TimerWrapper';
import Loader from '../../Global/HelperComponents/Loader';
import { BrowserRouter } from 'react-router-dom';
import { useFavicon, useRouterBasename } from '../../Global/globalHooks';
import ErrorAlert from '../../Global/Alerts/ErrorAlert';

const Router = () => {
  const { basename, isError, isLoading } = useRouterBasename();

  // TODO: refactor auth flow!!! security issue - consider to use lib for auth token

  // TODO: exclude "open in new tab" functionality from document details for .docx

  // Refactor document preview component

  if (isLoading) return <Loader />;

  if (isError) return <ErrorAlert />;

  return (
    <BrowserRouter basename={basename}>
      <TimerWrapper>
        <RoutesWrapper />
      </TimerWrapper>
    </BrowserRouter>
  );
};

export default Router;

import { useState, useEffect } from 'react';

export const useTimer = handleClick => {
  const [countDown, setCountDown] = useState(20);

  useEffect(() => {
    if (countDown === 0) {
      handleClick();
    }

    const timer = countDown > 0 && setInterval(() => setCountDown(countDown - 1), 1000);
    return () => clearInterval(timer);
  }, [countDown]);

  return [countDown];
};

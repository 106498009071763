import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const QueryClientWrapper = ({ children }) => {
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: 0
        }
      }
    })
  );

  useEffect(() => {
    queryClient.resetQueries();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

QueryClientWrapper.propTypes = {
  children: PropTypes.node
};

export default QueryClientWrapper;

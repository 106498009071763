import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useNotify } from './notificationsHelpers';
import 'react-toastify/dist/ReactToastify.css';

const NotificationsContainer = () => {
  useNotify();

  return <ToastContainer position='bottom-right' autoClose={3000} />;
};

export default NotificationsContainer;

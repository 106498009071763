import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCookieValue } from '../../Global/globalFunctions';
import { RouteNames } from './routes';

const PrivateRoute = ({ children, forAdminOnly }) => {
  const { isLogged } = useSelector(state => state.auth);
  const isAdmin = getCookieValue('authority');
  const location = useLocation();

  if (!isLogged) {
    return <Navigate to={RouteNames.login} replace state={{ from: location }} />;
  }

  if (forAdminOnly && !isAdmin) {
    return <Navigate to={RouteNames.notAuthorized} replace state={{ from: location }} />;
  }
  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  forAdminOnly: PropTypes.bool
};
export default PrivateRoute;
